import { mapGetters } from 'vuex';
import CHECK_SHOPIFY_APP_EMBED_STATUS from '@/graphql/CheckShopifyAppEmbedStatuses.gql';

export default {
  computed: {
    ...mapGetters(['shopSettingsByDomain']),
    shop() {
      return this.shopSettingsByDomain(this.domain);
    },
    myShopifyDomain() {
      return this.shop?.myshopify_domain;
    },
    isShopifyPlatform() {
      return this.platform === 'shopify';
    },
    shopifyAppEmbedEnabled() {
      return this.appEmbedStatuses?.[0]?.disabled === true;
    },
  },

  apollo: {
    appEmbedStatuses: {
      query: CHECK_SHOPIFY_APP_EMBED_STATUS,
      variables() {
        return {
          myshopifyDomains: [this.myShopifyDomain],
        };
      },
      skip() {
        return !this.isShopifyPlatform || !this.myShopifyDomain;
      },
    },
  },
};
