<template lang="pug">
.h-auto.w-100.platform-page
  page-wrapper(:title="title")
    template(slot="content")
      .d-flex.align-items-center.mb-4(v-if="domain")
        om-heading(h5) {{ domain }}
        om-button.ml-4(
          v-if="showChange"
          :data-track="trackIds.change"
          primary
          ghost
          small
          @click="changeDomain"
        ) {{ $t('change') }}
      component(v-if="$route.name.endsWith('setup')" :is="child" v-bind="$props")
      ConnectOptions.mt-5(v-else :platform="platform")
      portal(to="root")
        insert-code-help-v2
      .platform-page--links.d-flex.w-100.justify-content-between.align-items-center.mt-5
        om-link(
          v-if="!showSupportLink && !isConnected"
          :data-track-property="trackIds.manual"
          secondary
          @click="navigateToManual"
        ) {{ $t('codeInsertV2.buttons.anotherPlatform') }}
        .d-flex(v-else)
          om-body-text.mr-1(bt400sm) {{ $t('codeInsertV2.manual.link') }}
          om-link.support-link(
            primary
            :data-track-property="trackIds.support"
            small
            @click="openSupportLink"
          ) {{ $t('codeInsertV2.manual.supportPage') }}
        om-button(
          v-if="!isConnected"
          ghost
          icon="question-circle"
          :data-track="trackIds.help"
          @click="openHelpModal"
        ) {{ $t('codeInsertV2.buttons.help') }}
    template(slot="image")
      img.m-auto(
        :alt="`${platform} logo`"
        :src="require(`@/assets/admin/svg/insertcode/platform_${illustration}.svg`)"
      )
</template>

<script>
  import PlatformComponents, { PageWrapper, ConnectOptions } from '@/components/CodeInsert';
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import codeInsertShopifyMixin from '@/mixins/codeinsert/shopify';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';

  export default {
    components: {
      PageWrapper,
      InsertCodeHelpV2: () => import('@/components/Modals/InsertCode/Help.vue'),
      ...PlatformComponents,
      ConnectOptions,
    },
    mixins: [childRouteMixin, codeInsertShopifyMixin, domainStatusMixin],

    computed: {
      trackIds() {
        const pageId = this.$route.name.endsWith('code-insert-platform')
          ? 'insert-code-platform'
          : 'insert-code-setup';
        return {
          help: `${pageId}-help`,
          manual: `${pageId}-anotherPlatform`,
          support: `${pageId}-supportLink`,
          change: `${pageId}-change`,
        };
      },
      title() {
        const isPlatformSetup = this.$route.name.includes('platform-setup');
        if (this.isConnected) {
          return this.$t('codeInsertV2.titles.connected');
        }
        return isPlatformSetup
          ? this.$t('codeInsertV2.titles.connect')
          : this.$t('codeInsertV2.titles.almostThere');
      },
      isCustomPlatform() {
        return ['custom', 'other'].includes(this.platform);
      },
      isSetupRoute() {
        return this.$route.name.endsWith('setup');
      },
      showSupportLink() {
        return this.isCustomPlatform && this.isSetupRoute;
      },
      showChange() {
        return !this.$route.name.endsWith('code-insert-platform-setup');
      },
      child() {
        return this.platform;
      },
    },
    methods: {
      changeDomain() {
        if (this.isOnboarding) {
          this.$router.push({ name: 'code-insert-domain' });
        } else {
          this.$router.push({ name: 'domains' });
        }
      },
      navigateToManual() {
        const name = this.isOnboarding ? 'code-insert-manual' : 'settings-code-insert-manual';
        this.$router.push({ name, query: { ...this.$route.query } });
      },
      openHelpModal() {
        this.$modal.show('insert-code-help-v2');
      },
      openSupportLink() {
        window
          .open(
            'https://support.optimonk.com/hc/en-us/categories/4405601748754-Integrations',
            '_blank',
          )
          .focus();
      },
    },
  };
</script>
<style lang="sass">
  .support-link
    line-height: 20px
  .platform-page
    container: card / inline-size

    &--links
      @container (max-width: 550px)
        flex-direction: column
  .code-insert-step-indent
    padding-left: 1.625em
    padding-right: 1rem
    text-indent: -1.625em
</style>
